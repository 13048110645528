<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <input type="text" class="form-control" [(ngModel)]="serverName" />
      <hr />
      <button
        class="btn btn-primary"
        (click)="onCreateServer()"
        [disabled]="serverName === ''"
      >
        Add Server
      </button>
      <p *ngIf="serverCreated; else noServer">{{ serverCreationStatus }}</p>
      <ng-template #noServer>
        <p>No Server was created!</p>
      </ng-template>
      <app-server *ngFor="let server of servers"></app-server>
    </div>
  </div>
</div>
